import React from 'react'

const ReservoirLogoWhiteText = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="87"
      height="20"
      viewBox="0 0 87 20"
      fill="none"
      {...props}
    >
      <path
        d="M23.7727 6.57178C24.3413 6.53113 24.91 6.53113 25.4922 6.57178L25.6141 7.72354H25.6953C25.9119 6.81568 26.792 6.43628 28.0241 6.43628C28.0783 6.89698 28.0783 7.65579 28.0241 8.1165C26.4941 8.1165 26.0744 8.42815 25.7088 9.40376V13.6179C25.235 13.6856 24.233 13.6856 23.7727 13.6179V6.57178V6.57178Z"
        fill="#ECEDEE"
        className="letter"
      />
      <path
        d="M35.1731 10.691C34.2659 10.7588 32.1808 10.7723 31.0164 10.7046V11.0433C31.0164 12.0054 31.5715 12.1815 32.7359 12.1815C33.5348 12.1815 34.1035 12.1138 34.8617 11.9647C34.9158 12.4254 34.9158 13.0758 34.8617 13.5365C33.9139 13.6992 33.1557 13.7805 32.2621 13.7805C30.3123 13.7805 29.0802 13.1978 29.0802 11.4769V8.7669C29.0802 6.96473 30.38 6.43628 32.1808 6.43628C33.9681 6.43628 35.1731 7.19509 35.1731 9.13276V10.691ZM33.2775 9.05146C33.2775 8.13005 32.7495 8.02165 32.1537 8.02165C31.558 8.02165 31.0164 8.13005 31.0164 9.05146V9.57991L32.059 9.51216H33.2775V9.05146Z"
        fill="#ECEDEE"
        className="letter"
      />
      <path
        d="M41.618 11.9647C41.618 13.2791 40.589 13.7805 38.91 13.7805C38.1924 13.7805 37.3665 13.7263 36.5948 13.5908C36.5271 13.1029 36.5271 12.5474 36.5948 12.0731C37.3259 12.1951 38.057 12.2357 38.6257 12.2357C39.1673 12.2357 39.6818 12.1951 39.6818 11.7344V11.4769C39.6818 11.084 39.411 11.0162 38.9507 10.8943L37.7321 10.542C36.9603 10.3252 36.5406 9.83736 36.5406 8.94305V8.1978C36.5406 6.93763 37.6238 6.43628 39.3433 6.43628C40.0203 6.43628 40.7244 6.49048 41.5097 6.65308C41.5774 7.14089 41.5774 7.68289 41.5097 8.15715C40.6837 8.00809 40.1557 7.96744 39.5599 7.96744C38.9777 7.96744 38.4768 8.00809 38.4768 8.4688V8.72625C38.4768 9.06501 38.7882 9.18696 39.2079 9.30891L40.3452 9.62056C41.1847 9.85091 41.618 10.271 41.618 11.3008V11.9647V11.9647Z"
        fill="#ECEDEE"
        className="letter"
      />
      <path
        d="M49.1461 10.691C48.2389 10.7588 46.1538 10.7723 44.9894 10.7046V11.0433C44.9894 12.0054 45.5445 12.1815 46.7089 12.1815C47.5078 12.1815 48.0764 12.1138 48.8346 11.9647C48.8888 12.4254 48.8888 13.0758 48.8346 13.5365C47.8869 13.6992 47.1286 13.7805 46.235 13.7805C44.2853 13.7805 43.0532 13.1978 43.0532 11.4769V8.7669C43.0532 6.96473 44.353 6.43628 46.1538 6.43628C47.941 6.43628 49.1461 7.19509 49.1461 9.13276V10.691ZM47.2505 9.05146C47.2505 8.13005 46.7225 8.02165 46.1267 8.02165C45.531 8.02165 44.9894 8.13005 44.9894 9.05146V9.57991L46.0319 9.51216H47.2505V9.05146Z"
        fill="#ECEDEE"
        className="letter"
      />
      <path
        d="M50.8385 6.57178C51.4072 6.53113 51.9759 6.53113 52.5581 6.57178L52.6799 7.72354H52.7612C52.9778 6.81568 53.8579 6.43628 55.09 6.43628C55.1441 6.89698 55.1441 7.65579 55.09 8.1165C53.56 8.1165 53.1403 8.42815 52.7747 9.40376V13.6179C52.3008 13.6856 51.2989 13.6856 50.8385 13.6179V6.57178V6.57178Z"
        fill="#ECEDEE"
        className="letter"
      />
      <path
        d="M56.3898 6.62601C56.8637 6.55826 57.8656 6.55826 58.326 6.62601L59.8018 11.8428L61.2641 6.62601C61.7244 6.55826 62.6857 6.55826 63.1461 6.62601L60.9933 13.6179C60.3975 13.6856 59.1519 13.6856 58.5561 13.6179L56.3898 6.62601Z"
        fill="#ECEDEE"
        className="letter"
      />
      <path
        d="M70.6335 11.5177C70.6335 13.1979 69.3608 13.7806 67.4653 13.7806C65.5697 13.7806 64.297 13.1979 64.297 11.5177V8.72637C64.297 6.97841 65.5697 6.4364 67.4653 6.4364C69.3608 6.4364 70.6335 6.97841 70.6335 8.72637V11.5177ZM68.6974 9.11933C68.6974 8.18437 68.1287 8.07597 67.4653 8.07597C66.7883 8.07597 66.2331 8.18437 66.2331 9.11933V11.0976C66.2331 12.0191 66.7883 12.1275 67.4653 12.1275C68.1287 12.1275 68.6974 12.0191 68.6974 11.0976V9.11933Z"
        fill="#ECEDEE"
        className="letter"
      />
      <path
        d="M74.4111 5.52851C73.9372 5.59626 72.8676 5.59626 72.3802 5.52851L72.3531 3.9838C72.8405 3.91605 73.9643 3.91605 74.4517 3.9838L74.4111 5.52851ZM72.4343 6.62607C72.8947 6.55832 73.8966 6.55832 74.3705 6.62607V13.6179C73.8966 13.6857 72.8947 13.6857 72.4343 13.6179V6.62607Z"
        fill="#ECEDEE"
        className="letter"
      />
      <path
        d="M76.3473 6.5719C76.916 6.53125 77.4846 6.53125 78.0668 6.5719L78.1887 7.72366H78.2699C78.4866 6.81581 79.3666 6.4364 80.5988 6.4364C80.6529 6.89711 80.6529 7.65591 80.5988 8.11662C79.0688 8.11662 78.649 8.42827 78.2835 9.40388V13.618C77.8096 13.6857 76.8076 13.6857 76.3473 13.618V6.5719V6.5719Z"
        fill="#ECEDEE"
        className="letter"
      />
      <path
        d="M17.8287 13.645L11.5125 9.99999V2.70325L17.8287 6.35501V13.645Z"
        fill="#80D8FF"
      />
      <path
        d="M5.19621 13.645L11.5125 9.99999V2.70325L5.19621 6.35501V13.645Z"
        fill="#7ACFFF"
      />
      <path
        d="M11.5125 13.645L17.8287 9.99997L11.5125 6.35498L5.19621 9.99997L11.5125 13.645Z"
        fill="url(#paint0_linear_11_289)"
      />
      <path
        opacity="0.3"
        d="M11.5125 17.2967L5.19621 13.645V6.35498L11.5125 9.99997V17.2967Z"
        fill="#E4F0FE"
      />
      <path
        d="M5.19621 13.645L11.5125 17.2967V13.645L5.19621 10V13.645Z"
        fill="url(#paint1_linear_11_289)"
      />
      <path
        opacity="0.3"
        d="M11.5125 17.2967L17.8287 13.645V6.35498L11.5125 9.99997V17.2967Z"
        fill="#D0E6FF"
      />
      <path
        d="M17.8287 13.645L11.5125 17.2967V13.645L17.8287 10V13.645Z"
        fill="url(#paint2_linear_11_289)"
      />
      <path
        opacity="0.3"
        d="M11.5125 9.99999L17.8287 6.35501L11.5125 2.70325L5.19621 6.35501L11.5125 9.99999Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11_289"
          x1="5.43455"
          y1="11.3599"
          x2="17.7593"
          y2="8.60718"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C132CE" />
          <stop offset="1" stopColor="#425AFA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11_289"
          x1="7.62611"
          y1="11.2208"
          x2="9.44638"
          y2="17.2708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#425AFA" />
          <stop offset="0.16" stopColor="#5A52F2" />
          <stop offset="0.55" stopColor="#9241DE" />
          <stop offset="0.84" stopColor="#B436D3" />
          <stop offset="1" stopColor="#C132CE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11_289"
          x1="12.6302"
          y1="16.1518"
          x2="16.6613"
          y2="11.2104"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C132CE" />
          <stop offset="0.1" stopColor="#AB39D6" />
          <stop offset="0.28" stopColor="#8545E3" />
          <stop offset="0.47" stopColor="#684EED" />
          <stop offset="0.65" stopColor="#5355F4" />
          <stop offset="0.83" stopColor="#4659F9" />
          <stop offset="1" stopColor="#425AFA" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ReservoirLogoWhiteText
